export const X = "X";
export const Z = "Z";
export const ARROWS_DISTANCE = "Arrows distance";
export const CIRCLE_SIZE = "Circle size";
export const PODIUM_SCALE = "Podium scale";
export const RESIZE_MODEL = "Resize model";
export const ROTATE_MODEL = "Rotate model";
export const ROTATE_FLOORS = "Rotate floors";
export const LINE_START = "Line start";
export const LINE_WIDTH = "Line width";
export const LINE_COLOR = "Line color";
export const MAX_DISTANCE = "Max distance";
export const TRANSPARENCY = "Transparency";
export const SHOW_INITIAL_ANIMATION = "Show initial animation";
export const SHOW_OCEAN = "Show ocean";
export const LABEL_SIZE = "Label size";

