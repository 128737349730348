import gsap from 'gsap';
import React, { useEffect, useRef } from 'react'
import { GridHelper } from 'three';

const Grid = ({
    show,
    scene,
    gridSize
}) => {
    console.log("gridSize", gridSize)
    const grid = useRef();
    useEffect(() => {
        if (!grid.current) setGrid();
        return () => {
        //   scene.remove(grid.current);
        };
      }, []);

    useEffect(() => {
        console.log("show", show, grid.current)
          if (show) {
            //fade out grid
            gsap.to(grid.current.scale, {
              duration: 1,
              x: 1,
              y: 1,
              z: 1,
            });
          } else {
            //fade in grid
            gsap.to(grid.current.scale, {
              duration: 1,
              x: 0,
              y: 0,
              z: 0,
            });
          }
     
    
        return () => {};
      }, [show]);

      function setGrid() {
        // Set up the grid
        
        let divisions = 20; // This defines the number of divisions in the grid, adjust as needed
    
        grid.current = new GridHelper(gridSize, divisions);
        grid.current.scale.set(0, 0, 0);
        scene.add(grid.current);
    
        // // Create a canvas to draw the gradient texture
        // const size = 512;
        // const canvas = document.createElement("canvas");
        // canvas.width = size;
        // canvas.height = size;
        // const context = canvas.getContext("2d");
    
        // // Create a radial gradient (black to transparent)
        // const gradient = context.createRadialGradient(
        //   size / 2,
        //   size / 2,
        //   (size / 2) * 0.7,
        //   size / 2,
        //   size / 2,
        //   size / 2
        // );
        // gradient.addColorStop(0, "transparent");
        // gradient.addColorStop(0.8, "black");
        // gradient.addColorStop(1, "black");
    
        // // Apply the gradient to the canvas
        // context.fillStyle = gradient;
        // context.fillRect(0, 0, size, size);
    
        // // Create a texture from the canvas
        // const texture = new THREE.CanvasTexture(canvas);
    
        // // Create a ring geometry and apply the gradient texture
        // const ringGeometry = new THREE.RingGeometry(
        //   (gridSize / 2) * 0.7,
        //   gridSize / 2,
        //   64
        // );
        // const ringMaterial = new THREE.MeshBasicMaterial({
        //   map: texture,
        //   side: THREE.DoubleSide,
        //   transparent: true,
        // });
        // const ring = new THREE.Mesh(ringGeometry, ringMaterial);
        // ring.rotation.x = Math.PI / 2; // Rotate the ring to lie flat on the grid
        // ring.position.y = 1; // Raise the ring slightly above the grid
        // grid.current = ring;
        // scene.current.add(ring);
      };
    return null
}

export default Grid