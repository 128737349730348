import React, { useEffect, useRef, useState } from "react";
import {
  DoubleSide,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  PlaneGeometry,
  RepeatWrapping,
  SphereGeometry,
  TextureLoader,
  Vector3,
  PMREMGenerator,
} from "three";
import effectController from "./effectsController";
import { Sky } from "three/examples/jsm/objects/Sky.js";
import { Water } from "three/examples/jsm/objects/Water.js";

// import { Sky } from './Sky'
import sky from "../../assets/sky1.jpg";
import night from "../../assets/night.jpg";
import sunset from "../../assets/sunset.jpg";
import waterUrl from "../../assets/waternormals.jpg";
import full360 from "../../assets/360/DJI_360.JPG"
import gui from "../../utils/gui";
import { cameraRenderDistance } from ".";
// import { Water } from "../Water";
import Clouds from "./Clouds";
import Stars from "./Stars";
import gsap from "gsap";
import { createSunPosition } from "../../utils/threeJsUtils";
export const skies = {
  Day: sky,
  Night: night,
  Sunset: sunset,
};
const SkyController = ({
  scene,
  // gridSize,
  showOcean,
  renderer,
  animationEnded,
  modelsLoaded,
  environmentPreset,
  isRtl,
}) => {
  const skyDome = useRef();
  const loader = useRef();
  const sun = useRef();
  const sky = useRef();
  const water = useRef();
  const ldrCubeMap = useRef();

  useEffect(() => {
    if (!sky.current) {
      loader.current = new TextureLoader();
      sun.current = buildSun();
      sky.current = buildSky();
      water.current = buildWater();
      loadEnvironment()
      // const pmremGenerator = new PMREMGenerator(renderer);
      // scene.environment = pmremGenerator.fromScene(sky.current).texture;
      addGiuControls();
      guiChanged();

      // if (!skyDome.current) loadSky(skies.Day)
      // createSky();
      // createGroundPlane();
    }
    return () => {
      if (skyDome.current) {
        scene.remove(skyDome.current);
      }
    };
  }, []);
  useEffect(() => {
    const waterSpeed = 0.1;
    const animate = () => {
      if (water.current) {
        water.current.material.uniforms["time"].value += waterSpeed / 60.0;
      }
      requestAnimationFrame(animate);
    };

    animate();
  }, []);
  useEffect(() => {
    if (modelsLoaded) {
      if (!animationEnded) animateSkyUniforms(effectController["Sunset"], 10);
      else animateSkyUniforms(effectController["Sunset"], 1);
    }

    return () => {};
  }, [modelsLoaded]);
  // sunColor
  // waterColor
  useEffect(() => {
    handlePresetChange();
    return () => {};
  }, [environmentPreset]);

    const loadEnvironment = async () => {
      console.log("loadEnvironment");
      // const ldrCubeMap = await createCubeTexture(ldrUrls);
      const textureLoader = new TextureLoader();
      const equirectangularTexture = await new Promise((resolve, reject) => {
        textureLoader.load(full360, resolve, undefined, reject);
      });
    
      // Convert the equirectangular texture to a cube texture
      const pmremGenerator = new PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const ldrCubeRenderTarget = pmremGenerator.fromEquirectangular(equirectangularTexture);
      ldrCubeMap.current = ldrCubeRenderTarget.texture;
      scene.environment = ldrCubeMap.current;
      // let ldrCubeRenderTarget = pmremGenerator.fromCubemap( ldrCubeMap );

      // const size = 500
      // const geometry = new THREE.BoxGeometry(size, size, size);
      
      // const material = new THREE.MeshBasicMaterial({ envMap: ldrCubeMap, side: THREE.BackSide });
      // const cube = new THREE.Mesh(geometry, material);
      // cube.position.y = 500;
      // scene.current.add(cube);
  
    };
  const animateSkyUniforms = (targetPreset, duration = 3, onComplete) => {
    // console.log("Animating sky uniforms", targetPreset);
    const uniforms = sky.current.material.uniforms;

    const currentValues = {
      turbidity: uniforms["turbidity"].value,
      rayleigh: uniforms["rayleigh"].value,
      mieCoefficient: uniforms["mieCoefficient"].value,
      mieDirectionalG: uniforms["mieDirectionalG"].value,
      ...getElevationAndAzimuth(uniforms["sunPosition"].value),
    };

    const endValues = {
      turbidity: targetPreset.turbidity,
      rayleigh: targetPreset.rayleigh,
      mieCoefficient: targetPreset.mieCoefficient,
      mieDirectionalG: targetPreset.mieDirectionalG,
      elevation: targetPreset.elevation,
      azimuth: targetPreset.azimuth,
    };

    // Check if the target preset is the same as the current values
    if (JSON.stringify(currentValues) === JSON.stringify(endValues)) {
      console.log(
        "Target preset is the same as current values. No animation needed."
      );
      return;
    }

    gsap.to(currentValues, {
      duration: duration,
      turbidity: endValues.turbidity,
      rayleigh: endValues.rayleigh,
      mieCoefficient: endValues.mieCoefficient,
      mieDirectionalG: endValues.mieDirectionalG,
      elevation: endValues.elevation,
      azimuth: endValues.azimuth,
      onUpdate: () => {
        uniforms["turbidity"].value = currentValues.turbidity;
        uniforms["rayleigh"].value = currentValues.rayleigh;
        uniforms["mieCoefficient"].value = currentValues.mieCoefficient;
        uniforms["mieDirectionalG"].value = currentValues.mieDirectionalG;

        const sunPosition = createSunPosition(
          currentValues.elevation,
          currentValues.azimuth
        );
        uniforms["sunPosition"].value.copy(sunPosition);
      },
      onComplete: () => {
        // Ensure the uniforms match the end values when the animation completes
        uniforms["turbidity"].value = endValues.turbidity;
        uniforms["rayleigh"].value = endValues.rayleigh;
        uniforms["mieCoefficient"].value = endValues.mieCoefficient;
        uniforms["mieDirectionalG"].value = endValues.mieDirectionalG;

        const sunPosition = createSunPosition(
          endValues.elevation,
          endValues.azimuth
        );
        uniforms["sunPosition"].value.copy(sunPosition);
        onComplete && onComplete();
      },
    });
  };

  const handlePresetChange = () => {
    console.log("environmentPreset", environmentPreset);
    console.log(sky.current);
    console.log(water.current);
    if (animationEnded) {
      if (effectController[environmentPreset]) {
        scene.background = null;
        if (sky.current) {
          sky.current.visible = true
        }
        if (water.current) {
          water.current.visible = true
        }
        animateSkyUniforms(effectController[environmentPreset], 3);
        // if (sky.current) {
        //   gsap.to(sky.current.scale, {
        //     duration: 1,
        //     x: 1,
        //     y: 1,
        //     z: 1,
        //   });
        // }
        // if (water.current) {
        //   gsap.to(water.current.scale, {
        //     duration: 1,
        //     x: 1,
        //     y: 1,
        //     z: 1,
        //   });
        // }
      }
      else {
        
        scene.background = ldrCubeMap.current;
        // fade out sky water and grid          
        if (sky.current) {
          sky.current.visible = false
          // gsap.to(sky.current.scale, {
          //   duration: 1,
          //   x: 0,
          //   y: 0,
          //   z: 0,
          // });
        }
        if (water.current) {
          water.current.visible = false
          // gsap.to(water.current.scale, {
          //   duration: 1,
          //   x: 0,
          //   y: 0,
          //   z: 0,
          // });
        }
        
      }
      if (!showOcean) return;
      if (environmentPreset === "Grid") {
        //fade out water
        gsap.to(water.current.scale, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        });
      } else {
        //fade in water
        gsap.to(water.current.scale, {
          duration: 1,
          x: 1,
          y: 1,
          z: 1,
        });
      }
    }
  };

  const addGiuControls = () => {
    gui.get((gui) => {
      gui
        .add(effectController[environmentPreset], "turbidity", 0.0, 20.0, 0.1)
        .onChange(guiChanged);
      gui
        .add(effectController[environmentPreset], "rayleigh", 0.0, 4, 0.001)
        .onChange(guiChanged);
      gui
        .add(
          effectController[environmentPreset],
          "mieCoefficient",
          0.0,
          0.1,
          0.001
        )
        .onChange(guiChanged);
      gui
        .add(
          effectController[environmentPreset],
          "mieDirectionalG",
          0.0,
          1,
          0.001
        )
        .onChange(guiChanged);
      gui
        .add(effectController[environmentPreset], "elevation", -360, 360, 0.01)
        .onChange(guiChanged);
      gui
        .add(effectController[environmentPreset], "azimuth", -180, 180, 0.1)
        .onChange(guiChanged);
      // gui.add(effectController, "exposure", 0, 1, 0.0001).onChange(guiChanged);

      guiChanged();
    });
  };
  // Converts elevation and azimuth angles to Cartesian coordinates (x, y, z)

  // Converts Cartesian coordinates (x, y, z) to elevation and azimuth angles
  function getElevationAndAzimuth(sunPosition) {
    const { x, y, z } = sunPosition;

    const r = Math.sqrt(x * x + y * y + z * z); // Radius
    const phi = Math.acos(y / r); // Polar angle in radians
    const theta = Math.atan2(z, x); // Azimuthal angle in radians

    const elevation = 90 - phi * (180 / Math.PI); // Convert to degrees
    const azimuth = theta * (180 / Math.PI); // Convert to degrees

    return { elevation, azimuth };
  }

  function guiChanged() {
    if (!sky.current) return;
    console.log("guiChanged", effectController[environmentPreset]);
    const uniforms = sky.current.material.uniforms;
    uniforms["turbidity"].value = effectController[environmentPreset].turbidity;
    uniforms["rayleigh"].value = effectController[environmentPreset].rayleigh;
    uniforms["mieCoefficient"].value =
      effectController[environmentPreset].mieCoefficient;
    uniforms["mieDirectionalG"].value =
      effectController[environmentPreset].mieDirectionalG;

    const sunPosition = createSunPosition(
      effectController[environmentPreset].elevation,
      effectController[environmentPreset].azimuth
    );

    uniforms["sunPosition"].value.copy(sunPosition);

    renderer.toneMappingExposure = effectController[environmentPreset].exposure;
  }
  function buildSky() {
    const sky = new Sky();
    sky.scale.setScalar(10000);
    // sky.current.material.uniforms["sunPosition"].value.copy(sun.current);

    scene.add(sky);
    return sky;
  }
  function buildSun() {
    const sun = createSunPosition(
      effectController[environmentPreset].elevation,
      effectController[environmentPreset].azimuth
    );

    // Defining the x, y and z value for our 3D Vector
    // const theta = Math.PI * (0.49 - 0.5);
    // const phi = 2 * Math.PI * (0.205 - 0.5);
    // sun.x = Math.cos(phi);
    // sun.y = Math.sin(phi) * Math.sin(theta);
    // sun.z = Math.sin(phi) * Math.cos(theta);

    return sun;
  }
  function buildWater() {
    console.log("Building water", showOcean);
    if (!showOcean) return null;
    const waterGeometry = new PlaneGeometry(10000, 10000);
    const water = new Water(waterGeometry, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: loader.current.load(waterUrl, function (texture) {
        texture.wrapS = texture.wrapT = RepeatWrapping;
      }),
      alpha: 1.0,
      sunDirection: new Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: scene.fog !== undefined,
    });
    water.rotation.x = -Math.PI / 2;
    // water.position.y = -100;
    scene.add(water);

    return water;
  }
  // const createSky = () => {
  //   sky.current = new Sky();
  //   sky.current.scale.setScalar(450000);
  //   console.log("sky.current", sky.current);
  //   const phi = MathUtils.degToRad(90);
  //   const theta = MathUtils.degToRad(0);
  //   const sunPosition = new Vector3().setFromSphericalCoords(1, phi, theta);
  //   sky.current.material.uniforms.sunPosition.value = sunPosition;
  //   sky.current.material.uniforms.exposure = 0.0;

  //   gui.get((gui) => {
  //     gui
  //       .add(effectController[environmentPreset], "turbidity", 0.0, 20.0, 0.1)
  //       .onChange(guiChanged);
  //     gui
  //       .add(effectController[environmentPreset], "rayleigh", 0.0, 4, 0.001)
  //       .onChange(guiChanged);
  //     gui
  //       .add(
  //         effectController[environmentPreset],
  //         "mieCoefficient",
  //         0.0,
  //         0.1,
  //         0.001
  //       )
  //       .onChange(guiChanged);
  //     gui
  //       .add(
  //         effectController[environmentPreset],
  //         "mieDirectionalG",
  //         0.0,
  //         1,
  //         0.001
  //       )
  //       .onChange(guiChanged);
  //     gui
  //       .add(effectController[environmentPreset], "elevation", 0, 360, 0.1)
  //       .onChange(guiChanged);
  //     gui
  //       .add(effectController[environmentPreset], "azimuth", -180, 180, 0.1)
  //       .onChange(guiChanged);
  //     // gui.add(effectController, "exposure", 0, 1, 0.0001).onChange(guiChanged);

  //     guiChanged();
  //   });
  //   scene.add(sky.current);
  // };
  // const createGroundPlane = () => {
  //   const floorGeometry = new PlaneGeometry(100000, 100000); // Large plane to extend to the horizon
  //   const floorMaterial = new MeshStandardMaterial({
  //     color: "black",
  //     metalness: 0.2,
  //     roughness: 0.4,
  //     envMapIntensity: 0.5,
  //     side: DoubleSide,
  //   });
  //   const floor = new Mesh(floorGeometry, floorMaterial);
  //   floor.rotation.x = -Math.PI / 2;
  //   floor.position.y = 1;
  //   scene.add(floor);
  // };
  // const loadSky = async (image) => {
  //   // console.log("Loding sky", image);

  //   loader.current.load(image, async function (texture) {
  //     // console.log("texture", texture);
  //     // Create a sphere geometry
  //     var geometry = new SphereGeometry(
  //       gridSize / 2,
  //       60,
  //       40,
  //       0,
  //       Math.PI * 2,
  //       0,
  //       Math.PI / 2
  //     );

  //     // Flip the geometry so that the textured side faces inwards
  //     geometry.scale(-1, 1, 1);

  //     // Apply the texture to the sphere material
  //     var material = new MeshBasicMaterial({
  //       map: texture,
  //       transparent: true,
  //     });

  //     // Create a new mesh with the sphere geometry and the material, and add it to the scene
  //     var newSkyDome = new Mesh(geometry, material);
  //     // console.log("skyDome.current", skyDome.current);
  //     if (skyDome.current) {
  //       await fadeOutSkyDome(skyDome.current);
  //       await fadeInSkyDome(newSkyDome);
  //     } else await fadeInSkyDome(newSkyDome);
  //     // if (skyDome.current) {
  //     //   scene.remove(skyDome.current);
  //     // }

  //     // scene.add(skyDome.current);
  //   });
  // };
  // const fadeOutSkyDome = (skyDome, duration = 500) => {
  //   // console.log("Fading out skydome", skyDome);
  //   return new Promise((resolve) => {
  //     const start = performance.now();
  //     const fade = () => {
  //       const elapsed = performance.now() - start;
  //       const progress = Math.min(elapsed / duration, 1);
  //       skyDome.material.opacity = 1 - progress;
  //       if (progress < 1) {
  //         requestAnimationFrame(fade);
  //       } else {
  //         scene.remove(skyDome);
  //         resolve();
  //       }
  //     };
  //     fade();
  //   });
  // };

  // const fadeInSkyDome = (skyDome, duration = 500) => {
  //   console.log("Fading in skydome", skyDome);
  //   return new Promise((resolve) => {
  //     skyDome.material.opacity = 0;
  //     scene.add(skyDome);
  //     skyDome.current = skyDome;
  //     const start = performance.now();
  //     const fade = () => {
  //       const elapsed = performance.now() - start;
  //       const progress = Math.min(elapsed / duration, 1);
  //       skyDome.material.opacity = progress;
  //       if (progress < 1) {
  //         requestAnimationFrame(fade);
  //       } else {
  //         resolve();
  //       }
  //     };
  //     fade();
  //   });
  // };
  const setEnviroment = async (envName) => {
    console.log("envName", envName);
    animateSkyUniforms(effectController[envName]);
  };

  return (
    <>
      <Stars scene={scene} show={environmentPreset !== "Grid"} />
    </>
  );
};

export default SkyController;
