import React, { useEffect } from "react";
import {
  animateCamera,
  animateCameraPosition,
  animateCameraRotation,
} from "../utils/threeJsUtils";
import { Quaternion, Euler } from "three";
import gsap from "gsap";
export const initialCameraHeight = 100;
const iniaitlPosition = { x: 0, y: initialCameraHeight, z: 200 };
const CameraController = ({
  camera,
  projectDefaultView,
  onAnimationEnd,
  modelsLoaded,
  animationEnded,
}) => {
  useEffect(() => {
    camera.position.set(iniaitlPosition.x, iniaitlPosition.y, -100);
    if (!animationEnded) {
      // look up
      camera.rotation.x = -300;
      if (modelsLoaded) {
        let targetRotation = new Euler(0, 0, 0); // Target rotation in Euler angles
        // const targetQuaternion = new Quaternion().setFromEuler(targetRotation); // Convert to quaternion
        animateCameraRotation(camera, targetRotation, 10, "ease-in-out").then(
          async () => {
            await animateCameraPosition(camera, iniaitlPosition, 5);
            // onAnimationEnd()

            moveToDefaultView();
          }
        );
      }
    }
    else moveToDefaultView();
    return () => {};
  }, [modelsLoaded]);

  const moveToDefaultView = async () => {
    if (projectDefaultView && projectDefaultView.default_view) {
      if (projectDefaultView.default_view.position) {
        // targetRotation = new Euler(projectDefaultView.default_view.rotation.x, projectDefaultView.default_view.rotation.y, projectDefaultView.default_view.rotation.z); // Target rotation in Euler angles
        // const targetQuaternion = new Quaternion().setFromEuler(targetRotation);
        await animateCamera(
          camera,
          projectDefaultView.default_view.position,
          projectDefaultView.default_view.quaternion,
          1
        );
        onAnimationEnd();
      }
    }
  };
  // const animateCameraThroughPoints = (pointsArray) => {
  //     let currentIndex = 0;
  //     let points = [
  //       new THREE.Vector3(-400, initialCameraHeight + 400, 100),
  //       new THREE.Vector3(-100, initialCameraHeight + 100, -150),
  //       new THREE.Vector3(-20, initialCameraHeight + 100, -150),
  //       new THREE.Vector3(100, initialCameraHeight + 50, 20),
  //       new THREE.Vector3(170, initialCameraHeight + 20, 0),
  //     ];
  //     // Create a CatmullRomCurve3 from the points
  //     let curve = new THREE.CatmullRomCurve3(points);

  //     // Get a large number of points along the curve
  //     let curvePoints = curve.getPoints(100);
  //     // Start the GSAP animation
  //     let tl = gsap.timeline();
  //     for (let i = 0; i < curvePoints.length - 1; i++) {
  //       tl.to(camera.current.position, {
  //         duration: 0.05, // adjust the duration as needed
  //         x: curvePoints[i + 1].x,
  //         y: curvePoints[i + 1].y,
  //         z: curvePoints[i + 1].z,
  //         ease: "power1.inOut",
  //       });
  //     }
  //     // function animateToNextPoint() {
  //     //   if (currentIndex < points.length) {
  //     //     gsap.to(camera.current.position, {
  //     //       duration: 2, // animation duration in seconds
  //     //       x: points[currentIndex].x,
  //     //       y: points[currentIndex].y,
  //     //       z: points[currentIndex].z,
  //     //       ease: "power1",
  //     //       onComplete: animateToNextPoint, // when animation finishes, start the next one
  //     //     });

  //     //     currentIndex++;
  //     //   }
  //     // }
  //     // animateToNextPoint();
  //   };
  return null;
};

export default CameraController;
