import React, { useState, useEffect, useMemo, useRef} from 'react';
// import { useHistory} from 'react-router-dom';

import axios from 'axios';

import Scene from './ThreeScene';
import FramebusListener from './FramebusListener';
import HoverApp from './Components/HoverApp';

export function AppData({

}) {


  const [apps, setApps] = useState()
  const [saveStatus, setSaveStatus] = useState("Save");
  const [viewedApartment, setviewedApartment] = useState();
  const [hoveredApartment, setHoveredApartment] = useState();
  const [show3dMap, setShow3dMap] = useState(false);
  const [building, setBuilding] = useState()
  const [apartment, setApartment] = useState();
  const [cameraPosition, setCameraPosition] = useState();
  const [mousePosition, setMousePosition] = useState();
  const [appId, setAppId] = useState()
  const [isAdmin, setIsAdmin] = useState()
  const [frameBusMessaage, setFrameBusMessaage] = useState()
  const [floor, setFloor] = useState()
  // const [settings, setSettings] = useState()
  const [newProjectLoaded, setNewProjectLoaded] = useState(false)

  const wrapperRef = useRef()
  const settings = useRef()
  let baseUrl = process.env.REACT_APP_BASE_API || "http://east.local/";
  let baseApi = `${baseUrl}wp-json/fp/v1/`;
  if(window.location && window.location.host && window.location.host.indexOf('localhost') === -1){
    baseUrl = `${window.location.protocol}//${window.location.host}/`
    baseApi = `${baseUrl}wp-json/fp/v1/`
  }


  useEffect(() => {
    if (wrapperRef.current) {
      // console.log("setFrameBusMessaage")
      setFrameBusMessaage({action: "3dAppReady"})
    }

    return () => {
    };
  }, []); 


  const getProjectData = (products) => {
    const apartments = Object.keys(products).filter(key => products[key].type_building).map(productId => products[productId])
    // console.log("App data products", products)
    const appsWithModels = apartments.filter(product => product.type_building["3d_model_file"])
    const modelUrl = appsWithModels[0].type_building["3d_model_file"]
    const hdrUrl = appsWithModels[0].type_building["3d_model_hdr_file"]
    return {
      apartments,
      // modelUrl,
      // hdrUrl

    }
  }
  const handleMessage = (message, reply) => {
    // console.log("handleMessage", message)

    switch (message.action) {
      case "show-apartment": case "open apartment":
        // console.log({settings})
        // console.log("show-apartment", message)
        // console.log(settings.current.apartments)
        const selectedApp = settings.current.apartments.find(app => app.id && message.apartment && app.id == message.apartment.id)
        // console.log("selectedApp", selectedApp)
        selectedApp && setviewedApartment(selectedApp)
        break;
      // case "i-am-admin":
      //   if (message.token) setToken(message.token)
      //   break
      case "hover-apartment":
        if (message.apartment)
        setHoveredApartment(message.apartment.id)
        break
      case "show-building":
        setBuilding(message.building)
        break
      case "show-apartment-floor":
        if (message.apartment)
        setFloor({
          name: message.apartment.floor_name,
          building: message.apartment.type_building.name
        })
        else setFloor(null)
        break
      case "show3dMap":
        setShow3dMap(true)
        break
      case "set-camera-view": 
        
        console.log("set-camera-view", message)
        setCameraPosition(message.view.viewData)
        break
      case "newProjectLoaded":
        console.log("newProjectLoaded", message)
        let projectSettings = getProjectData(message.products)
        console.log("projectSettings", projectSettings)
        // setIsRtl(message.params.fp_lang == 'he' ? true : false)
        settings.current = {
          ...projectSettings,
          statuses: message.statuses,
          buildings: message.buildings,
          colors: message.colors,
          apartmentBlockFieldsOrder: message.apartmentBlockFieldsOrder,
          projectId: message.projectId,
          projectDefaultView: message.projectDefaultView,
          isRtl: message.params.fp_lang == 'he' ? true : false,
          hdrUrl: message.hdrUrl,
          // skyImage: message.skyImage,
          // isAdmin: true,
          isAdmin: message.isAdmin,
        }
        setNewProjectLoaded(true)
        // fetchData(message.projectId, message.params)
        break
      default:
        break;
    }
  }
  const saveApartmentViewData = async(viewData) => {
    // setSaveStatus("Saving")
    setFrameBusMessaage({action: "updateDataFieldPerType", data: {
      value: viewData,
      id: viewedApartment.id,
      field: 'default_view',
      type: 'apartment'
    }})
  }
  const saveBuildingCalibration = async(calibration, buildingId) => {
    console.log("calibration", calibration)
    console.log("buildingId", buildingId)
    // setSaveStatus("Saving")
    setFrameBusMessaage({action: "updateDataFieldPerType", data: {
      value: calibration,
      id: buildingId,
      field: 'default_view',
      type: 'building'
    }})
  }

  const saveProjectSettings = async(newSettings) => {
    console.log(newSettings)
    // setSaveStatus("Saving")
    setFrameBusMessaage({action: "updateDataFieldPerType", data: {
      value: newSettings,
      id: settings.current.projectId,
      field: 'default_view',
      type: 'project'
    }})
    // try {
    //   const response = await axios.post(`${baseApi}projects/` + settings.current.projectId, {
    //     default_view: newSettings,
       
    //   },
    //   {
    //     headers: {
    //       'Authorization': `Bearer ${token}`
    //     }
    //   }
    //   );
    //   console.log("response", response)
    //   if (response.data.success){

    //   }

    //   // setData(response.data);
    // } catch (error) {
    //   if (axios.isCancel(error)) {
    //     console.log('Request canceled:', error.message);
    //   } else {
    //     // Handle other errors
    //     console.error('Request error:', error);
    //   }
    // } finally {
    //   setSaveStatus("Save")
    // }
  }
  const onApartmentHover = (app, position) => {
    // console.log(app, position)
    setApartment(app)

    
  }
  const frameBus = useMemo(() => <FramebusListener
    onMessage={(message, reply) => handleMessage(message, reply, apps)}
    appId={appId}
    message={frameBusMessaage}
  />, [appId, frameBusMessaage])
  const scene = useMemo(
    () => {
      if (settings.current)
      return (
      <Scene
        {...settings.current}
        show3dMap={show3dMap}
        viewedApartment={viewedApartment}
        hoveredApartment={hoveredApartment}
        onApartmentHover={onApartmentHover}
        setMousePosition={position => setMousePosition(position)}
        onApartmentClick={app => setAppId(app.title)}
        saveApartmentViewData={saveApartmentViewData}
        saveProjectSettings={saveProjectSettings}
        floor={floor}
        saveBuildingCalibration={saveBuildingCalibration}
        cameraPosition={cameraPosition}
        saveStatus={saveStatus}
        selectedBuildingName={building}
        setSelectedBuildingName={buildingName => setBuilding(buildingName)}
        setModelReady={(completed) => setFrameBusMessaage({action: "modelReady", data: completed})}
        onAnimationEnd={() => setFrameBusMessaage({action: "animationEnd"})}
        
      />
    )},
    [viewedApartment, hoveredApartment, building, newProjectLoaded, saveStatus, floor, cameraPosition]
  );
  // if (modelUrl && !isLoading) {

    return (
      <div ref={wrapperRef}>
        {frameBus}
        {scene}
        {(window.innerWidth > 500 && mousePosition && settings.current) && <HoverApp 
          {...settings.current}
          apartment={apartment} 
          mousePosition={mousePosition}
          goToApartmentPage={(appId) => setFrameBusMessaage({action: "goToApartmentPage", data: appId})}
          />}
      </div>
    )

  // }
  // else return ( 
  //   <div>Loading</div>
  // )

}

export default AppData

