import React from 'react'

const CheckBox = ({ label, value, onChange }) => {
  // console.log("value", value)
    return (
      <div className="d-flex align-items-center justify-between">

        <input
          style={{
            width: "50px",
            textAlign: "center",
          }}
          name={label}
          type={'checkbox'}
  
          defaultChecked={value}
          // onBlur={(e) =>
          //   onChange({
          //     label,
          //     value: e.target.value,
          //   })
          // }
          onChange={(e) => onChange(e.target.checked)}
        />
          <label
          style={{
            width: "200px",
            textAlign: "left",
          }}
          htmlFor={label}
        >
          {label}
        </label>
      </div>
    );
  };

export default CheckBox