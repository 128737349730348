import React, { useEffect, useRef } from "react";
import { BufferGeometry } from "three";
import { Line } from "three";
import { LineBasicMaterial } from "three";
import { Group } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { initialLineWidth } from "../Settings/Settings";
import {
  createGradientCircle,
  createLabel,
  handleCircleSize,
} from "../../utils/threeJsUtils";
import { MeshBasicMaterial } from "three";
import { loadModelGLTF } from "../../utils/loadingManager";
const Models = ({
  scene,
  buildings,
  apartments,
  projectDefaultView,
  gradientColors,
  colors,
  onModelsLoaded,
  hoveredApartment,
}) => {
  const floorsNodes = useRef([]);
  const models = useRef({});
  const floorLabels = useRef([]);
  const floorLines = useRef([]);
  const apartmentNodes = useRef([]);
  const vacantColor = useRef();
  const occupiedColor = useRef();
  const vacantHoveredColor = useRef();
  const occupiedHoveredColor = useRef();
  useEffect(() => {
    vacantColor.current = new MeshBasicMaterial({
      color: colors.primary,
      transparent: true,
      opacity: projectDefaultView.transparency
        ? projectDefaultView.transparency
        : 0.3,
    });
    occupiedColor.current = new MeshBasicMaterial({
      color: 0xff8870,
      transparent: true,
      opacity: projectDefaultView.transparency
        ? projectDefaultView.transparency
        : 0.3,
    });
    vacantHoveredColor.current = new MeshBasicMaterial({
      color: colors.primary,
      transparent: true,
      opacity: 0.9,
    });
    occupiedHoveredColor.current = new MeshBasicMaterial({
      color: "FF8870",
      transparent: true,
      opacity: 0.9,
    });
    initialLoad();

    return () => {};
  }, []);

  const createLine = (start, end, color) => {
    // Create a line
    const material = new LineBasicMaterial({ color: color });
    const points = [];
    points.push(start);
    points.push(end);
    const geometry = new BufferGeometry().setFromPoints(points);
    const line = new Line(geometry, material);
    scene.add(line);
    return line;
  };
  const initialLoad = async () => {
    try {
      const loadedModels = await loadModels();
      // console.log("MODELS LOADED", apartmentNodes.current)
      onModelsLoaded({
        models: models.current,
        floorLines: floorLines.current,
        floorLabels: floorLabels.current,
        floorsNodes: floorsNodes.current,
        apartmentNodes: apartmentNodes.current,
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  async function loadModels() {
    return new Promise((resolve) => {
      const modelsGroup = new Group();
      modelsGroup.name = "modelsGroup";
      modelsGroup.visible = false;
      scene.add(modelsGroup);
      buildings.forEach((building, index) => {
        // console.log("building", building);
        // create a group to fold floor lines and labels
        const floorLinesGroup = new Group();

        if (building["3d_model_file"])
          loadModelGLTF(building["3d_model_file"]).then(model => {
              // console.log("model", model);
              model.castShadow = true;
              // console.log("building.default_view", building.default_view);
              if (building.default_view) {
                if (building.default_view.position) {
                  model.position.set(
                    building.default_view.position.x,
                    12,
                    building.default_view.position.z
                  );
                  floorLinesGroup.position.set(
                    building.default_view.position.x,
                    12,
                    building.default_view.position.z
                  );
                }

                if (building.default_view.rotation) {
                  model.rotation.set(0, building.default_view.rotation._y, 0);
                }

                floorLinesGroup.rotation.set(
                  0,
                  building.default_view.floorsLinesRotationY
                    ? building.default_view.floorsLinesRotationY
                    : 0,
                  0
                );
                if (building.default_view.scale) {
                  model.scale.set(
                    building.default_view.scale.x,
                    building.default_view.scale.x,
                    building.default_view.scale.x
                  );
                  floorLinesGroup.scale.set(
                    building.default_view.scale.x,
                    building.default_view.scale.x,
                    building.default_view.scale.x
                  );
                }
              } else {
                model.position.set(0, 12, 0);
                floorLinesGroup.position.set(0, 12, 0);
              }
              let appNodes = [];
              // console.log()
              model.traverse(function (node) {
                // node.visible = false
                // console.log(node.name)
                // if (index == 1) {
                if (node.name.includes("Floor") && node.name.includes("base")) {
                  // console.log("node", node);
                  node.userData["buildingName"] = building.name;
                  floorsNodes.current.push(node);

                  const floorLabel = createLabel(node.name.split("_")[1]);
                  let endPos,
                    startPos = {
                      x: model.position.x,
                      y: node.position.y,
                      z: node.position.z,
                    };
                  let lineWidth = projectDefaultView.lineWidth
                    ? +projectDefaultView.lineWidth
                    : initialLineWidth;

                  if (model.position.x > 0) {
                    endPos = {
                      x: model.position.x + lineWidth,
                      y: node.position.y,
                      z: model.position.z,
                    };
                  } else {
                    endPos = {
                      x: model.position.x - lineWidth,
                      y: node.position.y,
                      z: model.position.z,
                    };
                  }
                  const line = createLine(
                    startPos,
                    endPos,
                    projectDefaultView.floorLineColor
                      ? projectDefaultView.floorLineColor
                      : "white"
                  );
                  line.userData.startPos = startPos;
                  line.userData.endPos = endPos;
                  floorLabel.position.set(endPos.x, endPos.y, endPos.z); // Position it above the arrow
                  line.label = floorLabel;
                  floorLines.current.push(line);
                  floorLabels.current.push(floorLabel);
                  floorLabel.scale.set(10, 10, 1); // Position it above the arrow
                  floorLinesGroup.add(line);
                  floorLinesGroup.add(floorLabel);
                  floorLinesGroup.visible = building.show_floors_lines;
                }

                if (node.name.includes("bbox")) {
                  const app = apartments.find(
                    (app) =>
                      app.title == node.name.split("_")[0] ||
                      app.title == node.name.split("_")[1]
                  );
                  // console.log("app", app);
                  if (app) {
                    node.userData = {
                      apartment: app,
                    };

                    apartmentNodes.current.push(node);
                    if (
                      (node && node.userData.apartment.available == "פנוי") ||
                      node.userData.apartment.available == "Available"
                    ) {
                      node.material = vacantColor.current;
                    } else {
                      node.material = occupiedColor.current;
                    }
                  }
                }
              });

              modelsGroup.add(model);
              const circleRadius = 30;

              const circle = createGradientCircle(
                circleRadius,
                building.default_view && building.default_view.position
                  ? building.default_view.position.x
                  : 0,
                building.default_view && building.default_view.position
                  ? building.default_view.position.y
                  : 0,
                building.default_view && building.default_view.position
                  ? building.default_view.position.z
                  : 0,
                gradientColors,
                false
              );
              scene.add(circle);
              if (
                building.default_view &&
                building.default_view.circleRadiusOffset
              ) {
                handleCircleSize(
                  building.default_view.circleRadiusOffset,
                  circle
                );
              }
              models.current[building.name] = model;
              models.current[building.name].circle = circle;
              models.current[building.name].floorsGroup = floorLinesGroup;
              modelsGroup.add(floorLinesGroup);
              // models.current[building.name].buildingLabel = buildingLabel;
              if (index == buildings.length - 1) {
                modelsGroup.visible = true;
                resolve();

                // const lineWidth = projectDefaultView.lineWidth
                //   ? +projectDefaultView.lineWidth
                //   : initialLineWidth;
                // const lineStart = projectDefaultView.lineStart
                //   ? +projectDefaultView.lineStart
                //   : 0;
                // updatelinesWidth(lineWidth);
                // updateLinesStart(lineStart);
                // mainCircle.current = createGradientCircle(
                //   60,
                //   0,
                //   0,
                //   0,
                //   gradientColors,
                //   true
                // );
              }
            },
            // Called while loading is progressing
            function (xhr) {
              // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            // Called when loading has errors
            function (error) {
              console.log("An error happened", error);
            }
          );
      });
    });
  }

  return null;
};

export default Models;
