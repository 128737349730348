import React, { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { RepeatWrapping } from "three";

import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { FlyControls } from "three/examples/jsm/controls/FlyControls";
import { gsap } from "gsap";
import mapboxgl from "mapbox-gl";
import Settings, { initialLineWidth } from "./Components/Settings/Settings";
import {
  PODIUM_SCALE,
  ROTATE_FLOORS,
  ROTATE_MODEL,
  TRANSPARENCY,
  X,
} from "./constants";
import { Loader3DTiles } from "three-loader-3dtiles";
import { Reflector } from "three/examples/jsm/objects/Reflector.js";
import gui from "./utils/gui";

// import tileSet from './3D_TILES/3D_TILES/tileset.json'
import SkyController from "./Components/Enviroment/SkyController";

import Models from "./Components/Models/Models";
import {
  animateCamera,
  animateCameraPosition,
  calculateDestinationPoint,
  createGradientCircle,
  createLabel,
  createSunPosition,
  generateRadialFloorTexture,
  getCurrentViewData,
  handleCircleSize,
} from "./utils/threeJsUtils";
import Enviroment, { cameraRenderDistance } from "./Components/Enviroment";
import effectController from "./Components/Enviroment/effectsController";
import CameraController, {
  initialCameraHeight,
} from "./Components/CameraController";
import { Water } from "./Components/Water";
import waterUrl from "./assets/waternormals.jpg";
import piz_compressed from "./assets/piz_compressed.exr";
import { loadHdr, loadModelGLTF } from "./utils/loadingManager";
import Grid from "./Components/Grid";
import ApartmentSettings from "./Components/Settings/ApartmentSettings";
import useKey from "./hooks/useKey";

// import { OGC3DTile } from '@jdultra/threedtiles';

mapboxgl.accessToken =
  "pk.eyJ1IjoieWFtaXRoYWZha290IiwiYSI6ImNsaWhsMnBpczBxaTkzZHFyZDdpaWp4c3MifQ.N2Batt6nrfHv-rjUo_EYAQ";

const Scene = ({
  setMousePosition,
  viewedApartment,
  saveApartmentViewData,
  saveBuildingCalibration,
  saveProjectSettings,
  hoveredApartment,
  buildings,
  floor,
  // modelUrl,
  hdrUrl,
  show3dMap,
  isRtl,
  setModelReady,
  selectedBuildingName,
  apartments,
  onApartmentClick,
  onApartmentHover,
  colors,
  projectDefaultView,
  saveStatus,
  setSelectedBuildingName,
  skyImage,
  isAdmin,
  onAnimationEnd,
  cameraPosition
}) => {
  const [initialized, setInitialized] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(projectDefaultView.showInitialAnimation ? false : true);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [currentSkyPreset, setcurrentSkyPreset] = useState("Initial");

  // const shift =  useKey("Shift");
  // const [cameraMode, setCameraMode] = useState("Orbit");

  // const [currentSkyDome, setCurrentSkyDome] = useState("Day");
  let targetPosition;
  let api = {
    fov: (Math.atan(3 / 4) * 180) / Math.PI,
  };

  let vacantColor = useRef();
  let occupiedColor = useRef();
  let vacantHoveredColor = useRef();
  let occupiedHoveredColor = useRef();
  let arrowsRefs = useRef([]);
  const mountRef = useRef(null);
  const cameraMode = useRef("Orbit");
  let raycaster;
  let mouse;
  let pmremGenerator;
  let flyControls;
  // let loader;
  let dracoLoader;
  const gridSize =
    projectDefaultView && projectDefaultView.maxDistance
      ? projectDefaultView.maxDistance * 3
      : 600; // This defines the size of the grid, adjust as needed
  const appRef = useRef();
  const mainCircle = useRef();
  const southBuildingCircle = useRef();
  const northBuildingCircle = useRef();
  const controls = useRef();
  const renderer = useRef();
  const tilesRenderer = useRef();
  const scene = useRef();
  const camera = useRef();
  const models = useRef({});
  const mousePosition = useRef();
  const apartmentNodes = useRef([]);
  const floorsNodes = useRef([]);
  const movedFloorNodes = useRef([]);
  const hiddenNodes = useRef([]);
  const floorLines = useRef([]);
  const floorLabels = useRef([]);
  const currentSkyDome = useRef("Day");
  const floorLinesWidth = useRef(initialLineWidth);
  const clockRef = useRef();
  const tilesRuntimeRef = useRef();
  const floorLineStartXOffset = useRef(0);
  const podiumRef = useRef();
  const grid = useRef();
  const lookAtPosition = useRef();
  const shiftPressed = useRef(false);
  let mouseDown = false;

  let initialArrowsRadius = 100; // Set the radius as per your requirement
  // const source =
  //   "http://east.local/wp-content/uploads/2023/03/FullBuildings_v01.glb";
  const gradientColors = [
    {
      position: 0,
      color: "black",
    },
    {
      position: 0.7,
      color: "black",
    },
    {
      position: 1,
      color: colors.primary,
    },
  ];
  useEffect(() => {
    targetPosition = new THREE.Vector3();
    vacantColor.current = new THREE.MeshBasicMaterial({
      color: colors.primary,
      transparent: true,
      opacity: projectDefaultView.transparency
        ? projectDefaultView.transparency
        : 0.3,
    });
    occupiedColor.current = new THREE.MeshBasicMaterial({
      color: 0xff8870,
      transparent: true,
      opacity: projectDefaultView.transparency
        ? projectDefaultView.transparency
        : 0.3,
    });
    vacantHoveredColor.current = new THREE.MeshBasicMaterial({
      color: colors.primary,
      transparent: true,
      opacity: 0.9,
    });
    occupiedHoveredColor.current = new THREE.MeshBasicMaterial({
      color: "FF8870",
      transparent: true,
      opacity: 0.9,
    });

    !scene.current && init();
    // console.log("CREATING MAIN CIRCLE")

    southBuildingCircle.current = createGradientCircle(
      29,
      -18,
      0,
      18,
      gradientColors
    );
    scene.current.add(southBuildingCircle.current);
    northBuildingCircle.current = createGradientCircle(
      29,
      18,
      0,
      -20,
      gradientColors
    );
    scene.current.add(northBuildingCircle.current);
    return () => {
      floorLabels.current = [];
      floorLines.current = [];
      movedFloorNodes.current = [];
      floorsNodes.current = [];
      apartmentNodes.current = [];
      arrowsRefs.current = [];
      // mainCircle.current = null;
      // console.log("CLEANINGGGGGGGG");
      // cancelAnimationFrame(animate);
      // Remove the renderer from the DOM
      // mountRef.current.removeChild(renderer.current.domElement);
    };
  }, []);
  useEffect(() => {
    console.log("projectDefaultView", projectDefaultView);
    if (!projectDefaultView.showInitialAnimation) onAnimationEnd()
    if (projectDefaultView) {
      floorLinesWidth.current = projectDefaultView.lineWidth
        ? +projectDefaultView.lineWidth
        : initialLineWidth;
      floorLineStartXOffset.current = projectDefaultView.lineStart
        ? +projectDefaultView.lineStart
        : 0;
    }

    return () => {};
  }, [projectDefaultView]);
  useEffect(() => {
    if (cameraPosition) {
      console.log("cameraPosition", cameraPosition);
      disableControls()
      cameraMode.current = "Steady"

      animateCamera(camera.current, cameraPosition.position, cameraPosition.quaternion, 1);
      // setControlsTarget(lootAtTarget)
      // animateLookAtPosition(cameraPosition.position, 1);
    }
  

  }, [cameraPosition])
  
  useEffect(() => {
    // console.log({ hoveredApartment });
    if (hoveredApartment) setAllApartmentsNodes([hoveredApartment]);
  }, [hoveredApartment]);

  var intervalID;
  useEffect(() => {
    if (viewedApartment) {
      appRef.current = viewedApartment;
      setAllApartmentsNodes();
      cameraMode.current = "Orbit";
      enableControls()
      if (
        viewedApartment.default_view &&
        viewedApartment.default_view.position
      ) {
        targetPosition = viewedApartment.default_view.position;
        animateCameraPosition(camera.current, targetPosition, 1);
      }
    }
  }, [viewedApartment]);

  useEffect(() => {
    handleSelectedBuildingName();
    return () => {};
  }, [selectedBuildingName, models.current, mainCircle.current]);
  useEffect(() => {
    handleFloorAnimation();

    return () => {};
  }, [floor]);
  // useEffect(() => {
  //   if (animationEnded) {
  //     if (currentSkyPreset === "Grid") {
  //       //fade out grid
  //       gsap.to(grid.current.scale, {
  //         duration: 1,
  //         x: 1,
  //         y: 1,
  //         z: 1,
  //       });
  //     } else {
  //       //fade in grid
  //       gsap.to(grid.current.scale, {
  //         duration: 1,
  //         x: 0,
  //         y: 0,
  //         z: 0,
  //       });
  //     }
  //   }

  //   return () => {};
  // }, [currentSkyPreset]);
  function init() {
    clockRef.current = new THREE.Clock();

    mouse = new THREE.Vector2();
    scene.current = new THREE.Scene();
    raycaster = new THREE.Raycaster();
    createRenderer();
    pmremGenerator = new THREE.PMREMGenerator(renderer.current);
    pmremGenerator.compileEquirectangularShader();
    setCamera();
    setInputHandlers();
    loadHdrMap(hdrUrl);
    // gui.init()
    // loadSky(skies.Day);
    // setGrid();
    // setFlyControls();
    // setModelReady(true);
    loadDirectionArrows();
    // loadPodium();
    createPodium();
    // addLighting();
    // mainCircle.current = createGradientCircle(
    //   60,
    //   0,
    //   0,
    //   0,
    //   gradientColors,
    //   true
    // );
    // scene.current.add(mainCircle.current);
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener("resize", function () {
      var width = window.innerWidth;
      var height = window.innerHeight;
      renderer.current.setSize(width, height);
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();
    });

    function animate() {
      // const dt = clockRef.current.getDelta()
      // console.log(tilesRuntimeRef.current)
      // if (tilesRuntimeRef.current) {
      //   tilesRuntimeRef.current.update(dt, window.innerHeight, camera.current)
      // }

      requestAnimationFrame(animate);
      // console.log("controls.current", controls.current);
      // if (cameraMode.current === "Steady") {
      //   const lootAtTarget = calculateDestinationPoint({
      //     position: camera.current.position,
      //     quaternion: camera.current.quaternion,
      //   }, 100)
      //   lookAtPosition.current = lootAtTarget;
        
      // }
      // console.log(lookAtPosition.current);
      lookAtPosition.current && setControlsTarget(lookAtPosition.current);
      controls.current && controls.current.update(); // Only required if controls.enableDamping = true, or if controls.autoRotate = true
      // camera.current.updateMatrixWorld();
      // console.log(camera.position)
      // if (tilesRenderer.current && camera.current && renderer.current)
      // tilesRenderer.current.update();
      // camera.current.lookAt(tilesRenderer.current.group.position);
      renderer.current.render(scene.current, camera.current);
    }

    if (renderer.current && camera.current) {
      animate();
    }
    setInitialized(true);
  }
  async function handleFloorAnimation() {
    let targetPosition;
    if (floor) {
      // console.log(floorsNodes.current)
      const floorNode = floorsNodes.current.find(
        (node) =>
          +getFloorNumFromName(node.name) == floor.name &&
          node.userData.buildingName == floor.building
      );
      if (floorNode) {
        console.log({floorNode});
        const floorsAbove = floorsNodes.current.filter(
          (node) =>
            +getFloorNumFromName(node.name) > +floor.name &&
            node.userData.buildingName == floor.building
        );
        const sortedByFloorNum = floorsAbove.sort(
          (a, b) => +getFloorNumFromName(a.name) - +getFloorNumFromName(b.name)
        );
        // console.log(floorsAbove)
        movedFloorNodes.current = sortedByFloorNum;
        // console.log("movedFloorNodes.current", movedFloorNodes.current);
        gsap.utils
          .toArray(movedFloorNodes.current.reverse())
          .forEach((child, i) => {
            gsap.to(child.position, {
              y: child.position.y + 1000,
              duration: 1,
              ease: "power2.out",
              delay: i * 0.05, // Add delay here for stagger effect
            });
          });
        const model = models.current[floor.building];
        // console.log(model.position);
        const building = buildings.find((b) => b.name == floor.building);
        // console.log(building.default_view);
        const movedFloorsNames = movedFloorNodes.current.map(
          (node) => node.name
        );
        model.traverse(function (node) {
          if (
            node.position.y > floorNode.position.y &&
            !movedFloorsNames.includes(node.name)
          ) {
            node.visible = false;
            hiddenNodes.current.push(node);
          }
        });
        console.log({ building });
        if (building.default_view && building.default_view.floorsView) {
          targetPosition = new THREE.Vector3(
            building.default_view.floorsView.position.x,
            (floorNode.position.y + 50) * building.default_view.scale.y,
            building.default_view.floorsView.position.z
          );
          const lookAt = new THREE.Vector3(
            targetPosition.x,
            targetPosition.y - 50,
            targetPosition.z
          );
          floorLines.current.forEach((line) => (line.visible = false));
          floorLabels.current.forEach((label) => (label.visible = false));
          animateLookAtPosition(lookAt, 4);
          await animateCameraPosition(camera.current, targetPosition, 3);
        }

      }
    } else {
      if (movedFloorNodes.current && movedFloorNodes.current.length) {
        console.log("movedFloorNodes.current", movedFloorNodes.current);
        gsap.utils
          .toArray(movedFloorNodes.current.reverse())
          .forEach((child, i) => {
            gsap.to(child.position, {
              y: child.position.y - 1000,
              duration: 1,
              ease: "power2.out",
              delay: i * 0.05, // Add delay here for stagger effect
              onComplete: () => {
                movedFloorNodes.current = [];
              },
            });
          });
        targetPosition = projectDefaultView.default_view.position;
        floorLines.current.forEach((line) => (line.visible = true));
        floorLabels.current.forEach((label) => (label.visible = true));
        hiddenNodes.current.forEach((node) => (node.visible = true));
        animateCameraPosition(camera.current, targetPosition, 2);
      }
    }
  }
  function setControlsTarget(position) {
    controls.current && controls.current.target.set(position.x, position.y, position.z);
  }
  function animateLookAtPosition(
    targetPosition,
    duration,
    easeName = "power4.out"
  ) {
    if (targetPosition) {
      return new Promise((resolve) => {
        gsap.to(controls.current.target, {
          duration: duration,
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          ease: easeName, // or any other easing function
          // onUpdate: () => {
          //   camera.updateProjectionMatrix();
          // }, // required when the camera position is changing
          onComplete: resolve,
        });
      });
    }
  }
  const handleSelectedBuildingName = () => {
    // console.log("handleSelectedBuildingName", mainCircle.current);

    // if (mainCircle.current) {
    const selectedBuilding = buildings.find(
      (b) => b.name == selectedBuildingName
    );
    if (selectedBuilding) {
      // console.log("selectedBuilding", selectedBuilding);
      // mainCircle.current.visible = false;
      if (
        selectedBuilding &&
        selectedBuilding.default_view &&
        selectedBuilding.default_view.camera
      ) {
        targetPosition = selectedBuilding.default_view.camera.position;
        animateCamera(
          camera.current,
          selectedBuilding.default_view.camera.position,
          selectedBuilding.default_view.camera.quaternion,
          1
        );
        // animateCameraPosition(camera.current, targetPosition, 1);
      }
      // Object.keys(models.current).forEach((key) => {
      //   if (key == selectedBuildingName) {
      //     models.current[key].circle.visible = true;
      //   } else {
      //     models.current[key].circle.visible = false;
      //   }
      // });


      handleSelectedBuildingForEastProjectOnly(selectedBuildingName);
    } else {
      // mainCircle.current.visible = true;
      // Object.keys(models.current).forEach((key) => {
      //   models.current[key].circle.visible = false;
      // });
    }
    // }
    //  console.log(mainCircle.current)
  };
  const handleSelectedBuildingForEastProjectOnly = (selectedBuildingName) => {
    const southCamera = {
      position: {
        x: 89.97815368124795,
        y: 146.98262272594656,
        z: 137.82802698255836,
      },
      rotation: {
        x: -0.32852584006224983,
        y: 0.5534883968551999,
        z: 0.17730393668770358,
      },
      quaternion: {
        w: -0.1328207537746399,
        x: -0.1328207537746399,
        y: 0.2824157959584761,
        z: 0.039516937487675756,
      },
    };
    const northCamera = {
      position: {
        x: 142.8246311892743,
        y: 143.66212649833335,
        z: -83.63458277107074,
      },
      rotation: {
        x: -2.660454543596068,
        y: 0.9870258628409659,
        z: 2.730777725044315,
      },
      quaternion: {
        w: -0.06396059933432448,
        x: -0.06396059933432448,
        y: 0.8603535706333537,
        z: 0.11157352920821557,
      },
    };
    let targetPosition;
    if (
      selectedBuildingName == "מגדל דרומי" ||
      selectedBuildingName == "Southern tower"
    ) {
      targetPosition = southCamera.position;
      animateCameraPosition(camera.current, targetPosition, 1);
      if (southBuildingCircle.current)
        southBuildingCircle.current.visible = true;
      if (northBuildingCircle.current)
        northBuildingCircle.current.visible = false;
    } else if (
      selectedBuildingName == "מגדל צפוני" ||
      selectedBuildingName == "Northern tower"
    ) {
      targetPosition = northCamera.position;
      animateCameraPosition(camera.current, targetPosition, 1);
      // if (southBuildingCircle.current)
      //   southBuildingCircle.current.visible = false;
      // if (northBuildingCircle.current)
      //   northBuildingCircle.current.visible = true;
    }
  }
  const createArrowAndLabel = (model, position, rotation, labelName, labelPosition) => {
    const group = new THREE.Group();
    group.name = labelName;
    group.position.set(...position);
    // group.rotation.set(...rotation);
    let arrow = model.clone();
    // No rotation needed as 3D models in Three.js typically face towards positive Z by default
    // arrow.position.set(...position);
    arrow.rotation.set(...rotation);
    group.add(arrow);


    const label = createLabel(labelName);
    // const labelSouth = createLabel(isRtl ? "דרום" : "South");
    // label.position.set(...labelPosition); // Position it above the arrow
    label.position.y = 9;
    group.add(label);
    scene.current.add(group);
    return group;
  }
  
  const loadDirectionArrows = async () => {
    // Create gradient
    let canvas = document.createElement("canvas");
    canvas.width = 2;
    canvas.height = 2;

    let context = canvas.getContext("2d");
    context.fillStyle = colors.primary;
    context.fillRect(0, 0, 1, 2);

    // context.fillStyle = "black";
    context.fillRect(1, 0, 1, 2);

    // Create texture
    let texture = new THREE.CanvasTexture(canvas);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;

    const height = 5;
    const arrow = await loadModelGLTF(require("./arrow_3.glb"));
    arrow.traverse(function (node) {
      if (node.isMesh) {
        node.material.map = texture;
        node.material.needsUpdate = true;
      }
    });
    arrow.scale.set(50, 50, 10); // Scale the model
    arrow.rotation.x = Math.PI / 2; // Rotate 90 degrees around the x-axis
    arrow.position.y = height;
    let radius = initialArrowsRadius;
    if (projectDefaultView && projectDefaultView.arrowsRadiusOffset)
      radius += +projectDefaultView.arrowsRadiusOffset;

    const arrowsData = [
      {
        labelName: "S",
        position: [-radius, arrow.position.y, arrow.position.z],
        rotation: [arrow.rotation.x, arrow.rotation.y, Math.PI],
        labelPosition: [-radius, height + 4, 0],
      },
      {
        labelName: "W",
        position: [arrow.position.x, arrow.position.y, -radius],
        rotation: [arrow.rotation.x, arrow.rotation.y, -Math.PI / 2],
        labelPosition: [0, height + 4, -radius],
      },
      {
        labelName: "E",
        position: [arrow.position.x, arrow.position.y, radius],
        rotation: [arrow.rotation.x, arrow.rotation.y, Math.PI / 2],
        labelPosition: [0, height + 4, radius],
      },
      {
        labelName: "N",
        position: [radius, arrow.position.y, arrow.position.z],
        rotation: [arrow.rotation.x, arrow.rotation.y, arrow.rotation.z],
        labelPosition: [radius, height + 4, 0],
      },
    ]

    arrowsData.forEach((arrowData) => {
      const arrowGroup = createArrowAndLabel(arrow, arrowData.position, arrowData.rotation, arrowData.labelName, arrowData.labelPosition);
      arrowsRefs.current.push(arrowGroup);
    });
  };

  function createPodium() {
    let geometry, material;
    let scale = 1;
    if (projectDefaultView && projectDefaultView.podiumScale)
      scale = +projectDefaultView.podiumScale;

    geometry = new THREE.CylinderGeometry(90, 110, 10, 64);
    material = new THREE.MeshStandardMaterial({
      // side: THREE.DoubleSide,
      // roughness: 1,
      // metalness: 1,
      // color: colors.primary,
      map: generateRadialFloorTexture(1024),
      transparent: true,
      // depthWrite: false,
    });

    const podium = new THREE.Mesh(geometry, material);
    podium.position.y = 6;
    podium.renderOrder = 1;
    // podium.rotateX( - Math.PI / 2 );
    scene.current.add(podium);
    podiumRef.current = podium;
    changePodiumSize(scale);
    // return podium;
    // geometry = new THREE.CircleGeometry(100, 64);
    // const groundMirror = new Reflector(geometry, {
    //   clipBias: 0.003,
    //   textureWidth: window.innerWidth * window.devicePixelRatio,
    //   textureHeight: window.innerHeight * window.devicePixelRatio,
    //   color: 0x7f7f7f,
    // });
    // groundMirror.position.y = 5.1;
    // groundMirror.rotateX(-Math.PI / 2);
    // scene.current.add( groundMirror );
  }
  const changePodiumSize = (size) => {
    // console.log(podiumRef.current);
    podiumRef.current.scale.set(size, 1, size);
  };
  // const loadPodium = () => {
  //   // Create gradient
  //   let canvas = document.createElement("canvas");
  //   canvas.width = 2;
  //   canvas.height = 2;

  //   let context = canvas.getContext("2d");
  //   // context.fillStyle = colors.primary;
  //   context.fillRect(0, 0, 1, 2);

  //   context.fillStyle = "white";
  //   context.fillRect(1, 0, 1, 2);

  //   // Create texture
  //   let texture = new THREE.CanvasTexture(canvas);
  //   texture.wrapS = THREE.RepeatWrapping;
  //   texture.wrapT = THREE.RepeatWrapping;

  //   // const cubeMaterial3 = new THREE.MeshLambertMaterial( { color: 0xffaa00, envMap: reflectionCube, combine: THREE.MixOperation, reflectivity: 0.3 } );
  //   const reflectiveMaterial = new THREE.MeshStandardMaterial({
  //     color: 0x000000,
  //     // metalness: 1,
  //     // roughness: 0,
  //   });
  //   // const mirrorMaterial = new THREE.MeshBasicMaterial( { color: 0x111111, envMap: camera.current.renderTarget } );
  //   console.log("camera.current", camera.current)
  //   var mirrorMaterial = new THREE.MeshPhongMaterial( { emissive: 0x111111, envMap: camera.current.renderTarget } );
  //   const mat = new THREE.MeshStandardMaterial( {
  //     // side: THREE.DoubleSide,
  //     // roughness: params.roughness,
  //     // metalness: 0.0,
  //     // color: colors.primary,
  //     map: generateRadialFloorTexture( 1024 ),
  //     transparent: true,
  //   } )
  //   loader.load(require("./assets/podium.glb"), function (gltf) {
  //     console.log("podium loaded", gltf);
  //     let podium = gltf.scene; // Access the arrow model
  //     podium.receiveShadow = true;
  //     // Apply texture to model's materials
  //     podium.traverse(function (node) {
  //       if (node.isMesh) {
  //         node.material = mat;
  //         // node.material.map = texture;
  //         // node.material.envMap = null;
  //         // node.material = mirrorMaterial;
  //         node.material.needsUpdate = true;
  //       }
  //     });

  //     podium.scale.set(80, 10, 80); // Scale the model
  //     podium.position.y = 0.5;
  //     // gltf.scene.rotation.x = Math.PI / 2; // Rotate 90 degrees around the x-axis
  //     scene.current.add(podium);

  //   });
  // };

  const loadHdrMap = async (url) => {
    // console.log("url", url);
    const texture = await loadHdr(url);
    console.log("texture", texture);
    let envMap = pmremGenerator.fromEquirectangular(texture).texture;
    scene.current.environment = envMap;
  };
  const createRenderer = () => {
    renderer.current = new THREE.WebGLRenderer({ antialias: true });
    renderer.current.setSize(window.innerWidth, window.innerHeight);
    const mapDiv = document.getElementById("map");
    renderer.current.domElement.addEventListener(
      "webglcontextrestored",
      function () {},
      false
    );
    mapDiv.prepend(renderer.current.domElement);
  };

  const setFlyControls = () => {
    // Assuming you have initialized camera and renderer
    flyControls = new FlyControls(camera, renderer.current.domElement);

    flyControls.movementSpeed = 1.0;
    flyControls.domElement = renderer.current.domElement;
    flyControls.rollSpeed = Math.PI / 12;
    flyControls.autoForward = false;
    flyControls.dragToLook = false;
  };
  const addLighting = () => {
    // Add ambient light
    // const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
    // scene.current.add(ambientLight);
    // Add directional light
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    // directionalLight.position.set(1, 1, 1);
    // scene.current.add(directionalLight);
  };

  const getLinePositions = (line) => {
    // Get the positions attribute from the geometry
    const positions = line.geometry.attributes.position.array;

    // The start position is the first vertex
    const start = new THREE.Vector3(positions[0], positions[1], positions[2]);

    // The end position is the second vertex
    const end = new THREE.Vector3(positions[3], positions[4], positions[5]);

    return { start, end };
  };
  function createDropLabel(text) {
    const canvas = document.createElement("canvas");
    canvas.width = 200; // Set the width of the canvas
    canvas.height = 200; // Set the height of the canvas
    const context = canvas.getContext("2d");

    // Draw a wider drop shape
    context.beginPath();
    context.moveTo(100, 40);
    context.bezierCurveTo(200, 74, 190, 134, 100, 160);
    context.bezierCurveTo(10, 134, 0, 74, 100, 40);
    context.fillStyle = "white";
    context.fill();

    // Draw the text
    context.font = "30px Arial"; // Adjust the font size
    context.fillStyle = "black";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2); // Center the text vertically

    const texture = new THREE.CanvasTexture(canvas);
    const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(25, 25, 25); // scale the sprite to be wider
    sprite.name = text;
    scene.current.add(sprite);
    return sprite;
  }
  const setCamera = () => {
    camera.current = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      cameraRenderDistance * 2
    );
  };
  const setControls = () => {
    console.log("setControls");
    if (controls.current) return
    controls.current = new OrbitControls(
      camera.current,
      renderer.current.domElement
    );
    controls.current.screenSpacePanning = false
    controls.current.target.set(0, initialCameraHeight, 0);
    controls.current.enabled = false
    controls.current.enableRotate = false
    controls.current.enableDamping = false
    controls.current.enableZoom = false
    controls.current.enablePan = false
    controls.current.dampingFactor = 0.035;
    // controls.current.minPolarAngle = Math.PI / 9; // 30°
    controls.current.maxPolarAngle = Math.PI / 1.7; // 90°
    controls.current.minDistance = 50;
    controls.current.maxDistance = projectDefaultView.maxDistance
      ? projectDefaultView.maxDistance
      : 200;

      // controls.current.enabled = false  
  // Add event listeners for arrow keys
    // window.addEventListener('keydown', onKeyDown);
    // window.addEventListener('keyup', onKeyUp);
  };
  const enableControls = () => {
    console.log("disableControls", controls.current);
    if (controls.current) {
      controls.current.enabled = true
      controls.current.enableRotate = true
      controls.current.enableDamping = true
      controls.current.enableZoom = true
      controls.current.enablePan = true
      // controls.current.dispose(); // Remove all event listeners
      // controls.current = null; // Optionally, set controls to null to indicate they are disabled
    }
  };
  const disableControls = () => {
    console.log("disableControls", controls.current);
    if (controls.current) {
      controls.current.enabled = false
      // controls.current.enableRotate = false
      // controls.current.enableDamping = false
      // controls.current.enableZoom = false
      // controls.current.enablePan = false
      // controls.current.dispose(); // Remove all event listeners
      // controls.current = null; // Optionally, set controls to null to indicate they are disabled
    }
  };
  const onKeyDown = (event) => {
    console.log("onKeyDown", event.key);
    console.log("shiftPressed", shiftPressed.current);
    const speed = 1
    if (shiftPressed.current) {
      const lookAt = calculateDestinationPoint({
        position: camera.current.position,
        quaternion: camera.current.quaternion,
      }, 100)
      switch (event.key) {
        case 'ArrowUp':
          lookAt.y += speed * 2
          break;
        case 'ArrowDown':
          lookAt.y -= speed * 2
          break;
        case 'ArrowLeft':
          lookAt.x -= speed * 2
          break;
        case 'ArrowRight':
          lookAt.x += speed * 2;
          break;
      }
      lookAtPosition.current = lookAt;
    } else {

      switch (event.key) {
        case 'ArrowUp':
          camera.current.position.z -= speed;
          break;
        case 'ArrowDown':
          camera.current.position.z += speed;
          break;
        case 'ArrowLeft':
          camera.current.position.x -= speed;
          break;
        case 'ArrowRight':
          camera.current.position.x += speed;
          break;
        case "Shift":
          shiftPressed.current = true;
          break;
    }

    }
  };
  const onKeyUp = (event) => {
    // Optionally handle key up events if needed
    switch (event.key) {
      case "Shift":
        shiftPressed.current = false;
        break;
    
      default:
        break;
    }
  };
  const getFloorNumFromName = (name) => {
    return name.split("_")[1];
  };
  function onMouseDown(event) {
    mouseDown = true;
    const apartmetNode = checkIntersections("bbox");

    if (apartmetNode && apartmetNode.object.userData.apartment) {
      onApartmentClick(apartmetNode.object.userData.apartment);
      // if (movedFloorNodes.current && movedFloorNodes.current.length) {
      //   movedFloorNodes.current.reverse().forEach((child, i) => {
      //     gsap.to(child.position, {
      //       y: child.position.y - 200,
      //       duration: 1,
      //       ease: "power2.out",
      //       delay: i * 0.01, // Add delay here for stagger effect
      //       onComplete: () => {
      //         movedFloorNodes.current = [];
      //       }
      //     });
      //   });
      //   floorLines.current.forEach(line => line.visible = true);
      //   floorLabels.current.forEach(label => label.visible = true);
      //   animateCamera(camera.current, projectDefaultView.default_view.position, 1);
      // }
    }
  }
  const setInputHandlers = () => {
    renderer.current.domElement.addEventListener(
      "mousemove",
      onMouseMove,
      false
    );
    renderer.current.domElement.addEventListener(
      "mousedown",
      onMouseDown,
      false
    );
    renderer.current.domElement.addEventListener(
      "mouseup",
      function (event) {
        mouseDown = false;
      },
      false
    );
  };
  const setGrid = () => {
    // Set up the grid

    let divisions = 20; // This defines the number of divisions in the grid, adjust as needed

    grid.current = new THREE.GridHelper(gridSize, divisions);
    // grid.current.scale.set(0, 0, 0);
    scene.current.add(grid.current);

    // // Create a canvas to draw the gradient texture
    // const size = 512;
    // const canvas = document.createElement("canvas");
    // canvas.width = size;
    // canvas.height = size;
    // const context = canvas.getContext("2d");

    // // Create a radial gradient (black to transparent)
    // const gradient = context.createRadialGradient(
    //   size / 2,
    //   size / 2,
    //   (size / 2) * 0.7,
    //   size / 2,
    //   size / 2,
    //   size / 2
    // );
    // gradient.addColorStop(0, "transparent");
    // gradient.addColorStop(0.8, "black");
    // gradient.addColorStop(1, "black");

    // // Apply the gradient to the canvas
    // context.fillStyle = gradient;
    // context.fillRect(0, 0, size, size);

    // // Create a texture from the canvas
    // const texture = new THREE.CanvasTexture(canvas);

    // // Create a ring geometry and apply the gradient texture
    // const ringGeometry = new THREE.RingGeometry(
    //   (gridSize / 2) * 0.7,
    //   gridSize / 2,
    //   64
    // );
    // const ringMaterial = new THREE.MeshBasicMaterial({
    //   map: texture,
    //   side: THREE.DoubleSide,
    //   transparent: true,
    // });
    // const ring = new THREE.Mesh(ringGeometry, ringMaterial);
    // ring.rotation.x = Math.PI / 2; // Rotate the ring to lie flat on the grid
    // ring.position.y = 1; // Raise the ring slightly above the grid
    // grid.current = ring;
    // scene.current.add(ring);
  };
  function checkIntersections(string) {
    // console.log("modelNodes",modelNodes)
    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera.current);
    // console.log("scene.current.children",scene.current.children)
    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(scene.current.children, true);
    // If intersects is not empty, intersects[0] is the closest intersected object
    if (intersects && intersects.length > 0) {
      // console.log(intersects)

      // strings.forEach((string) => {
      //   if (intersects[0].object.name.includes(string)) {

      const node = intersects.find((node) => node.object.name.includes(string));
      // const floorNode = intersects.find((node) =>
      //   node.object.name.includes("bbox")
      // );
      return node;
    } else {
      return null;
    }
  }

  const setAllApartmentsNodes = (ids = []) => {
    if (appRef.current) ids.push(appRef.current.id);

    if (apartmentNodes.current) {
      if (ids.length) {
        apartmentNodes.current.forEach((node) => {
          // console.log(node)
          if (node && node.userData.apartment) {
            // console.log("found node with app", node.userData.apartment)
            if (!ids.includes(node.userData.apartment.id)) {
              if (
                node &&
                (node.userData.apartment.available == "פנוי" ||
                  node.userData.apartment.available == "Available")
              ) {
                node.material = vacantColor.current;
              } else {
                node.material = occupiedColor.current;
              }
            } else {
              if (
                node &&
                (node.userData.apartment.available == "פנוי" ||
                  node.userData.apartment.available == "Available")
              ) {
                node.material = vacantHoveredColor.current;
              } else {
                node.material = occupiedHoveredColor.current;
              }
            }
          }
        });
      } else {
        apartmentNodes.current.forEach((node) => {
          // console.log(node)
          if (node && node.userData.apartment) {
            // console.log(node.userData.apartment.available)
            if (
              (node && node.userData.apartment.available == "פנוי") ||
              node.userData.apartment.available == "Available"
            ) {
              node.material = vacantColor.current;
            } else {
              node.material = occupiedColor.current;
            }
          }
        });
      }
    }
  };
  function onMouseMove(event) {
    // Normalized device coordinates [-1 to +1]
    const deltaX = mousePosition.current ? event.clientX - mousePosition.current.x : 0;
    const deltaY = mousePosition.current ? event.clientY - mousePosition.current.y : 0;
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
    mousePosition.current = {
      x: event.clientX,
      y: event.clientY,
    };
    if (!mouseDown) {
      const apartmetNode = checkIntersections("bbox");
      // console.log("apartmetNode", apartmetNode)
      if (apartmetNode && apartmetNode.object.userData.apartment) {
        // console.log(apartmetNode.object.userData.apartment.id)
        setAllApartmentsNodes([apartmetNode.object.userData.apartment.id]);
        onApartmentHover(
          apartmetNode.object.userData.apartment,
          mousePosition.current
        );
        document.getElementById("map").style.cursor = "pointer";
      } else {
        // console.log(appRef.current)
        setAllApartmentsNodes();
        onApartmentHover();
        document.getElementById("map").style.cursor = "default";
      }
      setMousePosition(mousePosition.current);
    } 
    // else  {
    //   // console.log(mouse.x)
    //   if (cameraMode.current === "Steady") {
    //     console.log({ deltaX, deltaY });
    //     const lootAt = calculateDestinationPoint({
    //       position: camera.current.position,
    //       quaternion: camera.current.quaternion,
    //     }, 100)
    //     //animatelookAtPosition 
    //     const offSet = 1
    //     if (deltaX < 0) {
    //       lootAt.x += offSet;
    //     } else {
    //       lootAt.x -= offSet;
  
    //     }
    //     // if (deltaY < 0) {
    //     //   lootAt.y += offSet;
    //     // } else {
    //     //   lootAt.y -= offSet;
    //     // }
  
        
    //     lookAtPosition.current = lootAt;

    //   }
    //   // camera.current.lookAt(pos);
    // }
  }

  const saveProject = (form) => {
    console.log(form);

    let projectSettings = {};
    Object.keys(form.Project).forEach((key) => {
      projectSettings[form.Project[key].property] = form.Project[key].value;
    });
    Object.keys(form.Floors).forEach((key) => {
      projectSettings[form.Floors[key].property] = form.Floors[key].value;
    });
    const newSettings = {
      ...projectDefaultView,
      ...projectSettings,
    };
    console.log(newSettings);
    saveProjectSettings(newSettings);
  };
  const saveViewData = (type = "app", data) => {
    console.log("saving viewData", type, data);
    const viewData = getCurrentViewData(camera.current);
    let buildingToUpdate;
    switch (type) {
      case "app":

        saveApartmentViewData({
          ...viewedApartment.default_view,
          ...viewData,
        });
        break;
      case "app_view":
        
        saveApartmentViewData(data);
        break;
      case "building":
        buildingToUpdate = buildings.find(
          (b) => b.name == selectedBuildingName
        );
        saveBuildingCalibration(
          {
            ...buildingToUpdate.default_view,
            camera: viewData,
            position: models.current[selectedBuildingName].position,
            rotation: models.current[selectedBuildingName].rotation,
            scale: models.current[selectedBuildingName].scale,
            circleRadiusOffset:
              models.current[selectedBuildingName].circle.rasiusOffset,
            floorsLinesRotationY:
              models.current[selectedBuildingName].floorsGroup &&
              models.current[selectedBuildingName].floorsGroup.rotation.y
                ? models.current[
                    selectedBuildingName
                  ].floorsGroup.rotation.y.toFixed(2)
                : 0,
          },
          buildingToUpdate.id
        );
        break;
      case "building-floors":
        buildingToUpdate = buildings.find(
          (b) => b.name == selectedBuildingName
        );
        saveBuildingCalibration(
          {
            ...buildingToUpdate.default_view,
            floorsView: viewData,
          },
          buildingToUpdate.id
        );
        break;
      case "project":
        const newSettings = {
          ...projectDefaultView,
          default_view: viewData,
        };
        saveProjectSettings(newSettings);
        break;

      default:
        break;
    }
  };

  const handleModelRotation = (rotationY) => {
    models.current[selectedBuildingName].rotation.y = rotationY / 10;
  };
  const handleFloorsRotation = (rotationY) => {
    models.current[selectedBuildingName].floorsGroup.rotation.y =
      rotationY / 10;
  };
  const handleModelScale = (scale) => {
    console.log(selectedBuildingName);
    console.log(models.current);
    models.current[selectedBuildingName].scale.set(scale, scale, scale);
    models.current[selectedBuildingName].floorsGroup.scale.set(
      scale,
      scale,
      scale
    );
  };
  const handleArrowsDistance = (distance) => {
    console.log(arrowsRefs.current);
    // arrowsRadiusOffset.current = distance;
    arrowsRefs.current.forEach((arrow) => {
      if (arrow.position.x > 0)
        arrow.position.x = initialArrowsRadius + +distance;
      if (arrow.position.x < 0)
        arrow.position.x = -initialArrowsRadius - +distance;
      if (arrow.position.z > 0)
        arrow.position.z = initialArrowsRadius + +distance;
      if (arrow.position.z < 0)
        arrow.position.z = -initialArrowsRadius - +distance;
    });
  };

  const handleFloorsLinesColor = (color) => {
    floorLines.current.forEach((line) => {
      line.material.color.set(color);
    });
  };

  const updateLine = (line, start, end) => {
    // console.log({start, end})
    // Update the points in the geometry
    line.geometry.setFromPoints([start, end]);

    // Need to tell Three.js to update the line
    line.geometry.verticesNeedUpdate = true;
  };
  const updateLabelPosition = (label, position) => {
    label.position.set(position.x, position.y, position.z);
  };

  const updateLinesStart = (value) => {
    floorLineStartXOffset.current = value;
    floorLines.current.forEach((line) => {
      let startPos = { ...line.userData.startPos };
      let endPos = { ...startPos };

      if (line.userData.startPos.x > 0) {
        startPos.x = startPos.x + value;
        endPos.x = startPos.x + floorLinesWidth.current;
      } else {
        startPos.x = startPos.x - value;
        endPos.x = startPos.x - +floorLinesWidth.current;
      }
      updateLine(line, startPos, endPos);
      updateLabelPosition(line.label, endPos);
    });
  };
  const updatelinesWidth = (value) => {
    // console.log(floorLines.current);
    // console.log(floorLabels.current)
    floorLinesWidth.current = +value;
    floorLines.current.forEach((line) => {
      let startPos = { ...line.userData.startPos };
      let endPos = { ...startPos };
      if (line.userData.startPos.x > 0) {
        startPos.x += floorLineStartXOffset.current;
        endPos.x = startPos.x + +value;
      } else {
        startPos.x -= floorLineStartXOffset.current;
        endPos.x = startPos.x - +value;
      }
      updateLine(line, startPos, endPos);
      updateLabelPosition(line.label, endPos);
    });
  };
  const handleInputChange = (e, tab) => {
    // console.log(e);
    // console.log(tab);
    // console.log(models.current[selectedBuildingName])
    const { label, value } = e;
    switch (label) {
      case "X":
        models.current[selectedBuildingName].position.x = +value;
        break;
      case "Z":
        models.current[selectedBuildingName].position.z = +value;
        break;
      case "Arrows distance":
        handleArrowsDistance(+value);

        break;
      case PODIUM_SCALE:
        changePodiumSize(+value);
        // if (tab == "Project") handleCircleSize(+value, mainCircle.current);
        // else
        //   handleCircleSize(+value, models.current[selectedBuildingName].circle);
        break;
      case "Circle size":
        if (tab == "Project") handleCircleSize(+value, mainCircle.current);
        else
          handleCircleSize(+value, models.current[selectedBuildingName].circle);
        break;
      case "Resize model":
        handleModelScale(+value);
        break;
      case ROTATE_MODEL:
        handleModelRotation(+value);
        break;
      case ROTATE_FLOORS:
        models.current[selectedBuildingName].floorsGroup &&
          handleFloorsRotation(+value);
        break;
      case "Line width":
        updatelinesWidth(+value);
        break;
      case "Line start":
        updateLinesStart(+value);

        break;
      case "Line color":
        console.log(floorLines.current);

        handleFloorsLinesColor(value);
        break;
      case "Max distance":
        controls.current.maxDistance = +value;
        break;
      case TRANSPARENCY:
        console.log("TRANSPARENCY", value);
        vacantColor.current.opacity = value;
        occupiedColor.current.opacity = value;
        break;
      case ROTATE_MODEL + "X":
        // tilesRenderer.current.group.rotation.x = +value ;
        break;
      default:
        break;
    }
  };
  let style = {
    position: "absolute",
    top: 0,
    left: !isRtl ? "auto" : 0,
    right: !isRtl ? 0 : "auto",
    backgroundColor: "lightgrey",
    color: "black",
  };
  const handleEndInitialAnimation = useCallback(() => {
    setControls();
    enableControls();
    onAnimationEnd();
    setAnimationEnded(true);
  }, []);

  const onModelsLoaded = useCallback((data) => {
    // console.log("onModelsLoaded", data);
    models.current = data.models;
    floorLines.current = data.floorLines;
    floorLabels.current = data.floorLabels;
    floorsNodes.current = data.floorsNodes;
    apartmentNodes.current = data.apartmentNodes;
    setModelsLoaded(true);
    setModelReady(true);
    // if (
    //   projectDefaultView &&
    //   projectDefaultView.default_view &&
    //   projectDefaultView.default_view.position
    // )
    //   animateCamera(
    //     camera.current,
    //     projectDefaultView.default_view.position,
    //     0
    //   );
  }, []);

  return (
    <>
      <div ref={mountRef} />
      {initialized && (
        <>
          <CameraController
            camera={camera.current}
            projectDefaultView={projectDefaultView}
            onAnimationEnd={handleEndInitialAnimation}
            modelsLoaded={modelsLoaded}
            animationEnded={animationEnded}
            // position
          />
          <Models
            scene={scene.current}
            buildings={buildings}
            apartments={apartments}
            projectDefaultView={projectDefaultView}
            gradientColors={gradientColors}
            colors={colors}
            onModelsLoaded={onModelsLoaded}
          />
          <SkyController
            setcurrentSkyPreset={setcurrentSkyPreset}
            currentSkyPreset={currentSkyPreset}
            // gridSize={gridSize}
            scene={scene.current}
            renderer={renderer.current}
            camera={camera.current}
            animationEnded={animationEnded}
            modelsLoaded={modelsLoaded}
            isRtl={isRtl}
          />
          {/* <SkyBox scene={scene.current} gridSize={gridSize}/> */}
          <Grid 
            scene={scene.current} 
            gridSize={gridSize} 
            show={currentSkyPreset == "Grid" && animationEnded}
            />
        </>
      )}

      {isAdmin && (
        <div className="settings" style={style}>
          

          <Settings
            app={viewedApartment}
            buildings={buildings}
            projectDefaultView={projectDefaultView}
            // settingsForm={settingsForm}
            saveStatus={saveStatus}
            saveProjectSettings={saveProjectSettings}
            onChange={handleInputChange}
            saveProject={(form) => saveProject(form)}
            saveViewData={saveViewData}
            selectedBuildingName={selectedBuildingName}
            setSelectedBuildingName={setSelectedBuildingName}
          />
          {/* {viewedApartment && (
            <ApartmentSettings
              apartment={viewedApartment}
              saveViewData={saveViewData}
              camera={camera.current}
              />
          )} */}
          
        </div>
      )}
    </>
  );
};

export default Scene;
