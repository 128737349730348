import {LoadingManager, TextureLoader, RepeatWrapping, HalfFloatType, CubeTextureLoader} from 'three'
// import { TilesRenderer } from "3d-tiles-renderer";
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
// import {FontLoader } from 'three/examples/jsm/loaders/FontLoader '
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'
// import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
const loadingManager = new LoadingManager()
const textureLoader = new TextureLoader(loadingManager)
const cubeTextureLoader = new CubeTextureLoader(loadingManager)
const rgbeLoader = new RGBELoader(loadingManager);
rgbeLoader.setDataType(HalfFloatType);
const gltfLoader = new GLTFLoader(loadingManager)
const dracoLoader = new DRACOLoader()

dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/')
gltfLoader.setDRACOLoader(dracoLoader)

const onLoadCallbacks = []
const onProgressCallbacks = []

loadingManager.onLoad = () => {
  while (onLoadCallbacks.length) {
    onLoadCallbacks.shift().call()
  }
}

loadingManager.onProgress = (item, loaded, total) => {
  const _onProgressCallbacks = [...onProgressCallbacks]

  while (_onProgressCallbacks.length) {
    _onProgressCallbacks.shift()(loaded / total)
  }
}

const loadTexture = (src, callback) => {
  console.log('loadTexture', src)
  const texture = textureLoader.load(src, () => {
    typeof callback === 'function' && callback(texture)
  })

  texture.wrapS = texture.wrapT = RepeatWrapping

  return texture
}

const loadHdr = (src) => {
  return new Promise((resolve, reject) => {
    rgbeLoader.load(src, (texture) => {
      resolve(texture)
    })
  })
};
const createCubeTexture = (srcs = []) => {
  return new Promise((resolve, reject) => {
    cubeTextureLoader.load(srcs, (cubeMap) => {
      resolve(cubeMap)
    })
  })
};

const loadModelGLTF = (src) => {
  // console.log('loadModelGLTF', src)
  return new Promise((resolve, reject) => {
    gltfLoader.load(src, (resources) => {
      resolve(resources.scene)
    })
  })
}

const loadGLTF = async (modelSrc, diffuseSrc, normalSrc, aoSrc) => {
  const mesh = await loadModelGLTF(modelSrc)
  const diffuseMap = loadTexture(diffuseSrc)
  const normalMap = loadTexture(normalSrc)
  const aoMap = loadTexture(aoSrc)

  return {mesh, diffuseMap, normalMap, aoMap}
}

const onLoad = (callback) => {
  onLoadCallbacks.push(callback)

  window.aa = loadingManager
}

const onProgress = (callback) => {
  onProgressCallbacks.push(callback)
}

export {loadGLTF, loadTexture, onLoad, onProgress, loadModelGLTF, loadHdr, createCubeTexture}

// async function loadTileset() {
//   // const url = 'https://storage.googleapis.com/3dbia_organization_179/bid_1259/survey_619/Models/3d%20tiles/1614697780676/P2_Cesium/Scene/P2_Cesium.json';
//   const url =
//     "https://storage.googleapis.com/3dbia_organization_179/bid_1426/survey_701/Models/3d tiles/1678374212737/Scene/tileset_100M.json";
//   const viewPort = {
//     width: window.innerWidth,
//     height: window.innerHeight,
//     devicePixelRatio: window.devicePixelRatio,
//   };
//   const options = {
//     dracoDecoderPath:
//       "https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/draco",
//     basisTranscoderPath:
//       "https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/basis",
//   };
//   const result = await Loader3DTiles.load({
//     url,
//     viewPort,
//     renderer: renderer.current,
//     options,
//   });
//   // //   console.log(renderer.current)
//   // //   const result = new OGC3DTile({
//   // //     url: 'https://storage.googleapis.com/3dbia_organization_225/bid_1452/survey_736/Models/3d%20tiles/1720693983994/Scene/P1_NDOT_B3179_Ces.json',
//   // //     renderer: renderer.current
//   // // });
//   console.log("loadTileset", result);
//   const { model, runtime } = result;
//   tilesRuntimeRef.current = runtime;
//   scene.current.add(model);
// }


// console.log("TILES RENDERER", tileSet)
    // tilesRenderer.current = new TilesRenderer('https://storage.googleapis.com/3dbia_organization_179/bid_1259/survey_619/Models/3d%20tiles/1614697780676/P2_Cesium/Scene/P2_Cesium.json');
    // tilesRenderer.current = new TilesRenderer(tileSet);
    // tilesRenderer.current.setCamera( camera.current );
    // tilesRenderer.current.setResolutionFromRenderer( camera.current, renderer.current );
    // tilesRenderer.current.onLoadTileSet = () => {
    //   console.log("TILESET LOADED")
    //   const boundingSphere = new THREE.Sphere();

    //   // Get the bounding sphere of the tileset
    //   tilesRenderer.current.getBoundingSphere(boundingSphere);
    //   // console.log("sphereMesh", sphereMesh)
    //   // tilesRenderer.current.getBoundingSphere( sphereMesh );
    //   // scene.current.add( sphereMesh );
    //   // scene.current.add( sphereMesh.position );
    //   // console.log(tilesRenderer.current.group.rotation)
    //   // tilesRenderer.current.group.scale.set(.1)
    //   tilesRenderer.current.group.position.copy( boundingSphere.center ).multiplyScalar( - 1 );
    // }
    // tilesRenderer.current.addEventListener( 'load-tile-set', (tile) => {
    //   // console.log('load-tile-set', tile)

    //   // optionally center the tile set in case it's far off center

    // } );
    // // console.log(tilesRenderer.current)
    // // tilesRenderer.current.resolution = {
    // //   width: window.innerWidth,
    // //   height: window.innerHeight,
    // //   devicePixelRatio: window.devicePixelRatio
    // // };
    // scene.current.add( tilesRenderer.current.group );

      // const loadSky = async (image) => {
  //   // console.log("Loding sky", image);

  //   var loader = new THREE.TextureLoader();
  //   loader.load(image, async function (texture) {
  //     // console.log("texture", texture);
  //     // Create a sphere geometry
  //     var geometry = new THREE.SphereGeometry(
  //       gridSize / 2,
  //       60,
  //       40,
  //       0,
  //       Math.PI * 2,
  //       0,
  //       Math.PI / 2
  //     );

  //     // Flip the geometry so that the textured side faces inwards
  //     geometry.scale(-1, 1, 1);

  //     // Apply the texture to the sphere material
  //     var material = new THREE.MeshBasicMaterial({
  //       map: texture,
  //       transparent: true,
  //     });

  //     // Create a new mesh with the sphere geometry and the material, and add it to the scene
  //     var newSkyDome = new THREE.Mesh(geometry, material);
  //     if (skyDome.current) {
  //       await fadeOutSkyDome(skyDome.current);
  //       await fadeInSkyDome(newSkyDome);
  //     } else await fadeInSkyDome(newSkyDome);
  //     skyDome.current = newSkyDome;
  //     // scene.current.add(skyDome.current);
  //   });

  // };

    // const loadMapBoxMap = () => {
  //   var canvas = document.getElementById("map");
  //   // var canvas = document.createElement('canvas');
  //   canvas.width = window.innerWidth;
  //   canvas.height = window.innerHeight;
  //   // document.body.prepend(canvas);

  //   // var mapboxAccessToken = 'pk.eyJ1IjoieWFtaXRoYWZha290IiwiYSI6ImNsaWhsMnBpczBxaTkzZHFyZDdpaWp4c3MifQ.N2Batt6nrfHv-rjUo_EYAQ';
  //   // var mapboxUrl = 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=' + mapboxAccessToken;
  //   // var loader = new THREE.TextureLoader();
  //   // loader.load(mapboxUrl, function(texture) {
  //   //   var geometry = new THREE.PlaneGeometry(1, 1);
  //   //   var material = new THREE.MeshBasicMaterial({map: texture});
  //   //   var mesh = new THREE.Mesh(geometry, material);
  //   //   scene.current.add(mesh);
  //   // });
  //   // var map = new mapboxgl.Map({
  //   //   container: canvas, // Container ID
  //   //   style: 'mapbox://styles/yamithafakot/cliiinf6o009p01pgf7v21dsf', // Map style to use
  //   //   center: [34.796571, 32.073745], // Starting position [lng, lat]
  //   //   zoom: 16, // Starting zoom level
  //   //   pitch: 60,
  //   //   antialias: true
  //   // });
  //   // var texture = new THREE.Texture(canvas);
  //   // texture.needsUpdate = true;
  //   // var material = new THREE.MeshBasicMaterial({ map: texture });
  //   // var geometry = new THREE.PlaneGeometry(2, 2);
  //   // var plane = new THREE.Mesh(geometry, material);
  //   // scene.current.add(plane);

  //   // // map.on('viewreset', render);
  //   // // map.on('move', render);
  //   // map.on('load', function() {
  //   //   var mapCanvas = map.getCanvas();
  //   //   // mapCanvas.style.display = 'none';
  //   //   var geometry = new THREE.PlaneGeometry(1, 1);
  //   //   var texture = new THREE.CanvasTexture(mapCanvas);
  //   //   mapBoxTexture.current = texture;
  //   //   var material = new THREE.MeshBasicMaterial({ map: texture });
  //   //   var mesh = new THREE.Mesh(geometry, material);
  //   //   // mesh.rotation.x = -Math.PI / 2;
  //   //   // scene.current.add(mesh)
  //   //   // scene.current.background = mapBoxTexture.current
  //   //   // camera.current.position.z = 5; // control zoom by adjusting camera distance
  //   //   // camera.current.lookAt(scene.current.position); // look at the center of the scene
  //   //   // setIsReady(!isReady)
  //   // });
  // };
