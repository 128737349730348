import React from 'react'
import sunIcon from "../assets/icons/sun.png";
import moonIcon from "../assets/icons/moon.png";
import sunsetIcon from "../assets/icons/sunset.png";
import gridIcon from "../assets/icons/grid.png";
const Presets = ({
    animationEnded,
    isRtl,
    setPreset,

}) => {
    const buttons = [
        {
          name: "Day",
          icon: sunIcon,
        },
        {
          name: "Night",
          icon: moonIcon,
        },
        {
          name: "Sunset",
          icon: sunsetIcon,
        },
        {
          name: "Grid",
          icon: gridIcon,
        },
        {
          name: "360",
          icon: gridIcon
        }
      ]
      let style = {
        position: "fixed",
        bottom: "2rem",
        left: animationEnded ? "2rem" : "-100rem",
        right: "auto",
        zIndex: 10,
        width: "15rem",
        height: "4rem",
        backgroundColor: 'rgba(28, 28, 28, .6)',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderRadius: "1rem",
        transition: "all 0.5s",
      }
      if (!isRtl) {
        style = {
          ...style,
          left: "auto",      
          right: animationEnded ? "2rem" : "-100rem",
        }
      }
    return (
        <div
          className="enviroment"
          style={style}
        >
          {buttons.map((option, i) => {
            return (
              <ButtonWithIcon key={i} icon={option.icon} text={option.name} onClick={() => setPreset(option.name)} />
            );
          })}
          {/* <Clouds scene={scene} /> */}
    
          
        </div>
      )
    }

export default Presets

export const ButtonWithIcon = ({ icon, text, onClick }) => {
    return (
      <button onClick={onClick} style={{
        background: 'none',
        border: 'none',
      }}>
        <img src={icon} alt={text} style={{}} />
        {/* <div 
          className="text"
        style={{
          color: 'white',
        }}>{text}</div> */}
      </button>
    );
  };